.overlay
  .logo-top-R
    height 0
    animation poly-height .65s forwards ease-in-out
    animation-delay .36s

  .logo-bottom-right-R
    transform scale(0)
    transform-origin left top
    animation poly-animation .75s forwards ease-in-out
    animation-delay .75s

  .logo-top-hex,
  .logo-left-hex
    transform scale(0)
    animation poly-animation .75s forwards ease-in-out
    animation-delay 1.36s

  .logo-left-hex
    transform-origin center right
    animation-delay 1.2s

  .logo-top-hex
    animation-delay 1.5s
    transform-origin center 50px

  .bottom-R
    opacity 0
    animation fadein 1s forwards ease-in-out
    animation-delay 2.11s

@keyframes overlay-animation
  100%
    transform: translate(-40vw, -40vh)

@keyframes poly-width
  100%
    width: 100%

@keyframes poly-height
  100%
    height: 100%

@keyframes poly-animation
  100%
    transform: scale(1)

@keyframes fadein
  0%
    opacity: 0
  100%
    opacity: 1
