.footer
  background-color: $colors['slate']
  color: lighten($colors['grey'], 40)
  letter-spacing: .9px

  .heading--underline
    color: $rl-color['medium']

  .section-title
    color: lighten($colors['grey'], 40)
    z-index: 0

  a
    color: $colors['grey']
    &:active,
    &:hover
      color: $rl-color['medium']

.section-footer
  padding-top: 3rem
  padding-bottom: 3rem

.footer-nav
  margin-top: 6rem
  padding-left: 45px
  position: relative
  +media(palm)
    font-size: .8rem
    margin-top: 3rem
  &:before
    display: block
    content: ""
    position: absolute
    top: 50%
    left: 0
    width: 20px
    height: 1px
    background-color: $rl-color['medium']
  li
    display: inline-block
    margin-right: 15px
  svg
    width: 20px
    fill: rgba(255,255,255,.2)
    transition: fill .36s ease-in-out

  a
    display: inline-block
    &:hover
      svg
        fill: $rl-color['medium']

.footer-nav
  a
    text-decoration: none

.copyright
  text-align: center
  color: rgba(255,255,255,.4)
  a
    color: rgba(255,255,255,.4)
    text-decoration: none