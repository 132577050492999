@import '../../node_modules/twg-frontend-scaffolding/dist/_scaffolding.styl'
@import '../../node_modules/slick-carousel/slick/slick.css'
@import '../../node_modules/slick-carousel/slick/slick-theme.css'

// @import 'global/_mixins'
@import 'global/_reset'
@import 'global/_fonts'
@import 'global/_variables'
@import 'global/_container'

@import 'global/_typography'
@import 'global/_utilities'

@import 'objects/*'

body
  margin 0