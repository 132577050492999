.header
  text-align: right
  position: fixed
  padding-top: breakpoints.palm.gutter
  padding-bottom: breakpoints.palm.gutter
  width: 100%
  z-index: 1
  opacity: 0
  animation: fadein .36s ease-in-out forwards
  +media(palm)
    display: none

  li
    display: inline-block
    margin-left: 30px
  a
    display: inline-block
    text-decoration: none
    color: rgba($rl-color['gold'], .3)
    text-transform: uppercase
    &:hover
      color: $rl-color['gold']
  .svg-logo
    width: 40px

.nav-secondary
  width: 0%
  transition: .5s width ease-in-out

a:hover
  .nav-secondary
    width: 100%