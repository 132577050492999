.button
  padding: 15px 42px 16px
  border: 2px solid $rl-color['medium']
  text-decoration: none
  transition: all .36s ease-in-out
  margin-top: 1rem
  display: inline-block
  font-size: 1.2rem
  &:hover
    background-color: rgba($rl-color['medium'], .6)
    color: $colors['grey']
