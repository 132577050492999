.quoteSlider
  margin-bottom: 1rem
  +media(portable)
    margin-left: 0

.quoteSlider-content
  font-size: 20px
  line-height: 40px
  font-style: italic
  font-weight: 100
  margin-bottom: 30px
  opacity: .5
  &:before
    content: "\201C"
  &:after
    content: "\201D"
  +media(palm)
    margin-left: 0
    margin-right: 0

.quoteSlider-logo img
  max-height: 100px

.quoteSlider-person
  font-weight: bold
  font-size: 18px
  margin-bottom: 6px

.quoteSlider-image img
  border-radius: 50%
  max-width: 60px
  margin-left: auto
  margin-right: auto

.quoteSlider-position
  font-size: .8rem
  color: $colors['slate']
  opacity: .5

// SLICK OVERRIDES - NOT BEM COMPATABLE
.quoteSlider-slickContainer
  .slickSlider-arrow
    display: none !important
    width: 40px
    position: absolute
    cursor: pointer
    top: 50%
    svg
      fill: $colors['slate']
    &:hover
      svg
        fill: $colors['slate']

  .slickSlder-arrow--prev
    left: 0
    transform: translateY(-50%) translateX(-10px)

  .slickSlder-arrow--next
    right: 0
    transform: translateY(-50%) translateX(10px)