.section
  position: relative
  +media(palm)
    padding-top: 1.5rem
    padding-bottom: 1.5rem

.section-hero
  background: linear-gradient(-45deg, $rl-color['dark'] 0%, $rl-color['gold'] 100%)
  overflow: hidden
  color: rgba(0,0,0,.6)
  +media(palm)
    min-height: 100vh
  .section-title
    color: rgba(0,0,0,.6)
    opacity: 1

.section-title
  font-family: $font-sectionTitle
  font-weight: lighter
  font-size: 5rem
  z-index: -1
  position: relative
  display: inline-block
  margin-top: 0
  margin-bottom: 0
  line-height: 1
  opacity: .2
  +media(large)
    font-size: 18.75rem
    span
      font-size: 5.625rem
      position: absolute
      top: 3rem
      right: -5rem

.section-title--small
  font-size: 3.5rem

.section-about
  z-index: -1
  padding-bottom: 4rem
  padding-top: 4rem
  .section-title
    opacity: .5
    display: block
    text-align: right
    margin-bottom: -7rem
    z-index: 2

.section-speak
  .section-title
    opacity: .5
    display: block
    margin-bottom: -7rem
    z-index: 2

.profile
  position: relative
  margin-top: 30px
  margin-left: 30px
  &:before
    border: 5px solid rgba($rl-color['gold'], .5)
    position: absolute
    content: ''
    display: block
    width: 100%
    padding-top: 100%
    top: -30px
    left: -30px
    z-index: -1

.profile-about
  &:before
    border-color: rgba($rl-color['gold'], .5)

.profile-speak
  &:before
    border-color: rgba($rl-color['dark'], .5)

.section-title--right
  display: block
  text-align: right
  right: 5rem

.section-bigco
  .section-title
    color: $rl-color['medium']
    +media(large)
      span
        right: -5.8rem
        top: 1.25rem
    //   margin-left: -10px
    //   top: -3rem
  li
    filter: grayscale(100%)
    &:first-child img
      max-height: 80px
      +media(palm)
        max-height: 50px
  .clients
    padding-top: 3rem
    opacity: .5
    +media(palm)
      margin-left: -40px

.section-smallco
  .section-title
    span
      top: .5rem

.section-title
  &--gold
    color: $rl-color['gold']
  &--medium
    color: $rl-color['medium']
  &--dark
    color: $rl-color['dark']

.section-title--growth
  +media(large)
    line-height: 10rem
  span
    display: block
    position: relative
    right: auto
    top: -1rem
    +media(large)
      top: auto
      font-size: 18.75rem
      left: 10.8rem

.section-footer
  min-height: auto

.section-copyright
  background-color: $colors['slate-dark']
  padding-top: 1rem
  padding-bottom: 1rem
  min-height: auto