.clients
  padding-left: 0
  text-align: center
  img
    filter: grayscale(100)
  a
    display: block

.client--small
  max-width: 90px
  margin-left: auto
  margin-right: auto

.client-logo--small
  max-height: 100px