html, body
  margin: 0
  padding: 0
  width: 100%
  height: 100%

*, *:before, *:after
  box-sizing: border-box

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section
  display: block

img, svg, video, iframe
  max-width: 100%

ol
  word-wrap: break-word
  max-width: 100%

body
  overflow-x: hidden