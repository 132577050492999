#hero
  position: relative
  display: flex
  flex-direction: row
  canvas
    position: absolute
    top: 0
    left: 0

.hero-content
  margin: auto
  text-align: center
  height: 90vh
  padding-top: 30vh
  width: 100%
  padding-left: breakpoints.palm.gutter
  padding-right: breakpoints.palm.gutter
  
  a 
    position: relative
    z-index: 1
  +media(palm)
    padding-top: 25vh

  .section-title
    z-index: 1