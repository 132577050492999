.overlay
  display: flex
  flex-direction: row
  background-color: $colors['slate-dark']
  height: 100vh
  position: fixed
  width: 100%
  z-index: 100
  animation: loading .5s forwards ease-in-out
  animation-delay 2.75s

.overlay-content
  margin: auto
  animation-delay: 3s

  .svg-logo
    width: 150px
    transform-origin: center center

.svg-outline
  -webkit-text-stroke-color: black
  -webkit-text-stroke-width: 1px

@keyframes loading
  0%
    opacity: 1
  95%
    opacity: 0
  100%
    opacity: 0
    display: none
    position: absolute