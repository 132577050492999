.favorites
  margin-bottom: 3rem
  a
    display: block
    vertical-align: middle
  img
    width: 100%
  +media(palm)
    margin-top: 0
    .container
      padding-left: 0
      padding-right: 0

.favorites-image
  padding-top: 100%
  width: 100%
  max-width: 100%
  background-size: cover
  background-position: center
  text-align: center
  position: relative
  +media(palm)
    min-height: 150px
    min-width: 320px
    margin-left: auto
    margin-right: auto
  img
    position: absolute
    top: 50%
    left: 50%
    transform: translateX(-50%) translateY(-50%)

.favorite--revy img
  opacity: .6

