html,
body
  font-family: $font-body
  font-size: base-font-size
  line-height: 1.8

h1, h2, h3,
h4, h5, h6
  font-family: $font-heading

.heading
  font-weight: 200
  text-transform: uppercase

.heading--secondary
  text-transform: uppercase
  font-size: 1rem
  color: $rl-color['medium']

.heading--underline
  &:after
    margin-top: .5rem
    content: ''
    width: 3rem
    display: block
    border-bottom: 2px solid $rl-color['gold']

.block-link
  color: $rl-color['medium']

.copyright
  span
    display: inline-block

a
  transition: color .36s ease-in-out

.section-about, 
.section-speak
  a
    color: $rl-color['medium']

.section-hero
  a:visited
    color: currentColor
