+replicate('.u-hide')
  display: none

.u-screenReader
  position: absolute
  top: -99999px
  left: -99999px
  font-size: 0

.u-center
  text-align: center